<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="text"
    />
  </div>
</template>

<script>
import { formatFirstUpperCase } from '@/utils/index.js'

export default {
  name: 'PrivacyPolicy',
  metaInfo: {
    title: 'PrivacyPolicy',
    link: []
  },
  data () {
    return {
      text: `
        Terms of Use <br>
      <br>
      "App" as used here in refers to all products and services owned and operated by Fingertips Team.<br>
      <br>
      Terms<br>
      <br>
      By using Fingertips (the "App"), you are agreeing to be bound by these terms of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using the App. The materials contained in this App are protected by applicable copyright and trademark law.<br>
      <br>
      Use License<br>
      Permission is granted to temporarily download one copy of the materials (information or software) for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:<br>
      <br>
      modify or copy the materials;<br>
      <br>
      use the materials for any commercial purpose, or for any public display (commercial or non-commercial);<br>
      attempt to decompile or reverse engineer any software contained within the app;
      remove any copyright or other proprietary notations from the materials;
      transfer the materials to another person or "mirror" the materials on any other server.
      This license shall automatically terminate if you violate any of these restrictions and may be terminated by Fingertips at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
      Disclaimer
      The materials within Fingertips App are provided on an 'as is' basis. Fingertips makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
      Further, Fingertips does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials within the app.
      Limitations
      In no event shall Fingertips or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the App and the materials within Fingertips, even if Fingertips authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
      Accuracy of materials
      The materials appearing within Fingertips could include technical, typographical, or photographic errors. Fingertips does not warrant that any of the materials on its website are accurate, complete or current. Fingertips may make changes to the materials contained on its website at any time without notice. However Fingertips does not make any commitment to update the materials.
       <br>
       <br>
       Modifications
        <br>
        <br>
      Fingertips may revise these terms of use at any time without notice. By using the App you are agreeing to be bound by the then current version of these terms of use.
       <br>
       <br>
       Access
      <br>
      <br>
      You must provide at your own expense the equipment, Internet connections or mobile devices and/or service plans to access and use this app. Fingertips does not guarantee that Fingertips app is available in all geographic locations. You acknowledge that when You use Fingertips app, Your wireless carrier may charge you fees for data, messaging, and/or other wireless access. Check with your carrier to see if there are any such fees that apply to you. You are solely responsible for any costs You incur to access this application from your mobile device and/or PC device. Your right to use Fingertips app is also predicated on your compliance with any applicable terms of agreements you have with third parties when using the services.
       <br>
       <br>
       Governing Law and Disputes Resolution
      <br>
      <br>
      Except to the extent that: (i) any applicable additional terms incorporated into these Terms provide differently, or (ii) the applicable laws and regulations of your jurisdiction mandate otherwise, the Agreement and any dispute or claim arising out of or in connection with the Agreement will be governed by the laws of the People’s Republican of China.
      <br>
      <br>
      Any dispute, controversy or claim (whether in contract, tort or otherwise) arising out of, relating to, or in connection with the Agreement, including their existence, validity, interpretation, performance, breach or termination, will be referred to and finally resolved by arbitration administered by the China International Economic and Trade Arbitration Commission (CIETAC), and the place of arbitration shall be in Beijing. Within the valid period for the arbitration, such arbitration tribunal shall subject to the arbitration rules of the CIETAC in effect at the time of the arbitration. The award of the arbitration tribunal shall be final and binding upon the two parties.
      <br>
      <br>
      Definitions and Miscellaneous
      <br>
      <br>
      Fingertips is a software product developed, operated and managed by Fingertips Team with the aim to provide you with a royalty-free, non-exclusive and global software license and technical services within the scope of this Agreement.
      <br>
      <br>
      Fingertips refers to Fingertips Team.
      <br>
      <br>
      Contact
      <br>
      <br>
      If you have any questions, complaints or claims with respect to our Services, you may contact us at
      <br>
      <br>
      finge7164@gmail.com
           `,
      project: ' Netboom Game +'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || ' Netboom Game +'
    this.project = formatFirstUpperCase(flavor)
  }
}
</script>

<style lang="less">
@import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
.wrapper {
  padding: 0 5%;
  background: #F0F0F0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 100%;
  box-sizing: border-box;
}
</style>
